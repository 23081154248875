<script lang="ts">
  import { dataStore } from "@stores/datastore";
  import StoryList from "@components/tree/StoryList.svelte";
  import { ExclamationCircleIcon } from "@rgossiaux/svelte-heroicons/outline";

  const { _storyHierarchy, _selectedNode } = dataStore;

  let filteredGraphs;

  const setFilteredGraphs = () => {
    filteredGraphs = $_storyHierarchy.map(copy).filter(function f(o) {
      if (o.nodes.some((n) => n.id == $_selectedNode.id)) {
        return true;
      }
      if (o.children) {
        return (o.children = o.children.map(copy).filter(f)).length;
      }
    });
  };

  function copy(o) {
    return Object.assign({}, o);
  }

  $: ($_storyHierarchy && $_selectedNode) && setFilteredGraphs();
</script>

<!-- <div class="mb-2 text-xl font-bold">Graphs</div> -->
{#if filteredGraphs.length == 0}
  <div class="flex flex-col justify-center pt-4 pb-4 mr-4 rounded-lg bg-base-300">
    <div class="flex m-3">
      <ExclamationCircleIcon class="w-5 h-5 ml-2 text-info"/>
      <span class="ml-3">Include this concept in a graph</span>
    </div>
    <span class="ml-6 opacity-50  text-base-content">
      To include this concept in a graph, please right-click on the graph you
      want in the left panel and search this concept in the "Add to graph" text
      field.
    </span>
  </div>
{:else}
  <StoryList
    data-testid="stories-list"
    class="flex flex-col"
    storyHierarchy={filteredGraphs}
    displayNewButton={false}
    displayDeselectButton={false}
    displayPastille={true}
    onStoryClick="toggle"
    hideIfEmpty={true}
    isScenario={false}
  />
  <!-- <StoryList
    data-testid="scenarii-list"
    class="flex flex-col"
    storyHierarchy={filteredGraphs}
    displayNewButton={false}
    displayDeselectButton={false}
    displayPastille={true}
    onStoryClick="toggle"
    hideIfEmpty={true}
    isScenario={true}
  /> -->
{/if}
