<script lang="ts">
  import { dataStore } from "@stores/datastore";
  import { displayStore } from "@stores/displaystore";
  import { displayOrders } from "@utils/categories";
  import {
    ChevronDoubleDownIcon as DChevronD,
    ChevronDoubleRightIcon as DChevronR,
  } from "@rgossiaux/svelte-heroicons/solid";
  import { Popover } from "@core-ui";
  import { InformationCircleIcon } from "@rgossiaux/svelte-heroicons/outline";
  import ConceptTree from "./ConceptTree.svelte";

  const { _nodesHierarchy } = dataStore;
  const { _categoryDisplay } = displayStore;
  const { _theme } = displayStore;

  let display = true;
  let nodeClickAction:((node) => {})

  function setDisplay() {
    display = !display;
  }
</script>

<!--
@component
Tree representation of the graph.
-->
<Popover
  triggeredBy={`#concept-hierarchy-tree`}
  class="text-sm font-light w-96 z-20"
  placement="right"
  defaultClass="p-0"
  arrow={true}
>
  <div class="grid grid-cols-5">
    <div class="col-span-3 p-3">
      <div class="space-y-2">
        <h3 class="font-semibold text-gray-900 dark:text-white">
          Create new concept
        </h3>
        <p class="text-gray-500 dark:text-gray-500">
          You can right click on a concept type to create a new concept
        </p>
      </div>
    </div>
  </div>
</Popover>
<div
  class="flex flex-row items-center justify-center w-full h-10 gap-2 pt-6 pb-3 my-2 cursor-pointer"
>
  <span on:click={setDisplay}>
    {#if display}
      <DChevronD class="w-6 h-6" />
    {:else}
      <DChevronR class="w-6 h-6" />
    {/if}
  </span>
  <div class="flex-1 text-xl font-bold" on:click={setDisplay}>Concepts</div>
  <InformationCircleIcon
        class="w-4 h-4"
        id={`concept-hierarchy-tree`}
      />
  <!-- <Can roles={[Roles.DEV, Roles.STORY, Roles.CONCEPT]}>
    <CreateConcept />
  </Can> -->
</div>
{#if display && $_nodesHierarchy}
  <ConceptTree  
     {nodeClickAction} 
     {displayOrders} 
     categoryDisplay={$_categoryDisplay} 
     {...$$restProps}
  />
{:else if $_nodesHierarchy == null}
    <div><span class={$_theme == "dark" ? 'loader' : `loader2`}></span></div>
{/if}

<style>
  .loader {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  .loader2 {
    width: 48px;
    height: 48px;
    border: 5px solid #000;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
  } 
</style>
  
