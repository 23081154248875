/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Node } from '../models/Node';
import type { NodesWithStories } from '../models/NodesWithStories';
import type { Resource } from '../models/Resource';
import type { Resources } from '../models/Resources';
import type { Sector } from '../models/Sector';
import type { Sectors } from '../models/Sectors';
import type { UpdateNode } from '../models/UpdateNode';
import type { UpdateStatus } from '../models/UpdateStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NodesService {

    /**
     * Get Node
     * @param id
     * @returns NodesWithStories Successful Response
     * @throws ApiError
     */
    public static getNodeApiV1NodesIdGet(
        id: number,
    ): CancelablePromise<NodesWithStories> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/V1/nodes/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Put Node
     * @param id
     * @param requestBody
     * @returns NodesWithStories Successful Response
     * @throws ApiError
     */
    public static putNodeApiV1NodesIdPut(
        id: number,
        requestBody: UpdateNode,
    ): CancelablePromise<NodesWithStories> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/V1/nodes/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Node
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteNodeApiV1NodesIdDelete(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/V1/nodes/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Insert Node
     * @param requestBody
     * @returns NodesWithStories Successful Response
     * @throws ApiError
     */
    public static insertNodeApiV1NodesPost(
        requestBody: Node,
    ): CancelablePromise<NodesWithStories> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/V1/nodes/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Status
     * @param id
     * @param requestBody
     * @returns NodesWithStories Successful Response
     * @throws ApiError
     */
    public static updateStatusApiV1NodesIdStatusPut(
        id: number,
        requestBody: UpdateStatus,
    ): CancelablePromise<NodesWithStories> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/V1/nodes/{id}/status',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Node Resources
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getNodeResourcesApiV1NodesIdResourcesGet(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/V1/nodes/{id}/resources',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Resource To Node
     * @param id
     * @param requestBody
     * @returns Resources Successful Response
     * @throws ApiError
     */
    public static addResourceToNodeApiV1NodesIdResourcesPost(
        id: number,
        requestBody: Resource,
    ): CancelablePromise<Resources> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/V1/nodes/{id}/resources',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Envprocess To Node
     * @param id
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static addEnvprocessToNodeApiV1NodesIdEnvProcessPost(
        id: number,
        requestBody: Array<number>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/V1/nodes/{id}/envProcess',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Node Sectors
     * @returns Sectors Successful Response
     * @throws ApiError
     */
    public static getNodeSectorsApiV1NodesIdSectorsGet(): CancelablePromise<Array<Sectors>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/V1/nodes/{id}/sectors',
        });
    }

    /**
     * Add Sector To Node
     * @param id
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static addSectorToNodeApiV1NodesIdSectorsPost(
        id: number,
        requestBody: Sector,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/V1/nodes/{id}/sectors',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Node Sectors
     * @param id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static updateNodeSectorsApiV1NodesIdSectorsPatch(
        id: number,
        requestBody: Array<Sector>,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/V1/nodes/{id}/sectors',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove Sector From Node
     * @param id
     * @param sectorId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static removeSectorFromNodeApiV1NodesIdSectorsSectorIdDelete(
        id: number,
        sectorId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/V1/nodes/{id}/sectors/{sectorId}',
            path: {
                'id': id,
                'sectorId': sectorId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
