<script lang="ts">
  import { nodeTypes } from "@utils/nodeTypes";

  import {
    LinkIcon as Link,
    PaperClipIcon as PaperClip,
  } from "@rgossiaux/svelte-heroicons/outline";

  import {
    InformationCircleIcon as InformationCircle,
    PuzzleIcon,
  } from "@rgossiaux/svelte-heroicons/solid";

  import GraphIcon from "@components/icons/GraphIcon.svelte";

  import { dataStore } from "@stores/datastore";
  import Resources from "@components/Editors/Resources/index.svelte";
  import CreateResource from "@components/Actions/CreateResource/Trigger.svelte";
  import CreatePart from "@components/Actions/CreatePart.svelte";
  import GraphSection from "@components/Editors/NodeEditor/GraphSection.svelte";
  import PropertySection from "@components/Editors/NodeEditor/PropertySection.svelte";
  import NumberedPastille from "@components/Editors/NumberedPastille.svelte";
  import LinkSection from "@components/Editors/NodeEditor/linkSection.svelte";
  import TabButton from "../Tabs/TabButton.svelte";
  import { hasSupporting, Supporting } from "@utils/supporting";
  import { Popover } from "@core-ui";
  import { InformationCircleIcon } from "@rgossiaux/svelte-heroicons/outline";

  import { edgesOf } from "@utils/node";
  import { getCtx as getResourceCtx } from "@components/Actions/CreateResource/context";
  import { Type } from "../../../client/models/Type";
  import PartsSection from "./PartsSection.svelte";
  import { authentication } from "@utils/keycloak";
  import { Roles } from "@utils/decorators";

  let { resources } = getResourceCtx();

  const { _selectedNode, _stories } = dataStore;

  const popup = [
    { tab: "tab-related", title: "Connection", placement: "top" },
    { tab: "tab-attachments", title: "Evidences", placement: "top" },
    { tab: "tab-graphs", title: "Graphs", placement: "top" },
    { tab: "tab-properties", title: "Properties", placement: "top" },
    { tab: "tab-parts", title: "Parts", placement: "top" },
  ];

  // The "related" panel display either related concepts and connections
  // panel types
  type TabButtonId =
    | "related"
    | "attachments"
    | "graphs"
    | "properties"
    | "parts";
  let activeButtonId: string = "properties";
  let related;
  let properties;
  let attachments;
  let graphs;

  const computeNbOfProperties = () => {
    // All concept type have at least the metric property:
    let nbOfProperties = 1;

    // Simple (not supporting class based) properties:
    if (nodeTypes[$_selectedNode.type].properties) {
      nbOfProperties += nodeTypes[$_selectedNode.type].properties.length;
    }
    // Sector property:
    if (hasSupporting($_selectedNode.type, Supporting.Sectors)) {
      nbOfProperties += 1;
    }
    // Pressure agent:
    if ($_selectedNode.type == "pressure") {
      nbOfProperties += 1;
    }
    return nbOfProperties;
  };

  /**
   * Shows the tab content panel associated with given key and hides
   * the other ones. Styles the associated tab button with the
   * "active-button" class.
   *
   * @param {string} id: the id of the tab content panel to show.
   */
  const onTabClicked = (id: TabButtonId) => {
    let elems = document.getElementsByClassName("tabcontent");

    Array.prototype.forEach.call(elems, function (elem) {
      elem.style.display = "none";
    });
    document.getElementById(id).style.display = "block";
    activeButtonId = id;
  };

  const initTab = () => {
    if (Roles.DEV === $authentication.role) {
      activeButtonId = "properties";
      onTabClicked("properties");
    } else if (Roles.STORY === $authentication.role) {
      activeButtonId = "graphs";
      onTabClicked("graphs");
    } else if (Roles.CONCEPT === $authentication.role) {
      activeButtonId = "properties";
      onTabClicked("properties");
    } else if (Roles.CONTENT === $authentication.role) {
      activeButtonId = "attachments";
      onTabClicked("attachments");
    } else if (Roles.VIEWER === $authentication.role) {
      activeButtonId = "attachments";
      onTabClicked("attachments");
    }
  };

  $: related && properties && graphs && attachments && initTab();

  $: $_selectedNode;
</script>

{#each popup as { tab, title, placement }}
  <Popover
    triggeredBy={`#${tab}`}
    defaultClass="font-semibold font-light z-20 p-2 bg-base-100  rounded-lg"
    {placement}
    arrow={true}
  >
    <div class="grid bg-base-100">{title}</div>
  </Popover>
{/each}

<!--
@component
Tabbed panel component for editors.
-->
{#if $_selectedNode}
  <div class="flex flex-col h-full w-full">
    <div class="mb-4 border-b border-gray-200 tabs dark:border-gray-700">
      <TabButton
        {activeButtonId}
        tabId="related"
        {onTabClicked}
        title=" "
        id="tab-related"
      >
        <Link class="w-6 h-6 mr-2" />
        <NumberedPastille
          nbr={edgesOf($_selectedNode.id).length}
          class="flex items-center justify-center "
        />
      </TabButton>
      <TabButton
        {activeButtonId}
        tabId="graphs"
        {onTabClicked}
        title=" "
        id="tab-graphs"
      >
        <GraphIcon class="w-6 h-6 mr-2" />
        <NumberedPastille
          nbr={$_stories.filter((s) =>
            s.nodes.some((n) => n.id === $_selectedNode.id)
          ).length}
        />
      </TabButton>
      <TabButton
        {activeButtonId}
        tabId="properties"
        {onTabClicked}
        title=" "
        id="tab-properties"
      >
        <InformationCircle class="w-6 h-6 mr-2" />
        <NumberedPastille nbr={computeNbOfProperties()} />
      </TabButton>
      <TabButton
        {activeButtonId}
        tabId="attachments"
        {onTabClicked}
        title=" "
        id="tab-attachments"
      >
        <PaperClip class="w-6 h-6 mr-2" />
        <NumberedPastille nbr={$resources.length} />
      </TabButton>
      {#if $_selectedNode.type === Type.COMPONENT}
        <TabButton
          {activeButtonId}
          tabId="parts"
          {onTabClicked}
          title=" "
          id="tab-parts"
        >
          <PuzzleIcon class="w-6 h-6 mr-2" />
          <NumberedPastille nbr={$_selectedNode.aggregate.length} />
        </TabButton>
      {/if}
    </div>
    <div id="tabs" class="overflow-hidden flex flex-col h-full w-full">
      <!-- Display "related" object types i.e. connections for concepts and concepts for connections -->
      <div
        id="related"
        class="tabcontent"
        role="tabpanel"
        bind:this={related}
        aria-labelledby="related-tab"
      >
        <div class="title">Connections</div>
        <LinkSection direction="from" />
        <div class="h-4" />
        <LinkSection direction="to" />
      </div>
      <div
        id="graphs"
        class="tabcontent"
        role="tabpanel"
        bind:this={graphs}
        aria-labelledby="graphs-tab"
      >
        <GraphSection />
      </div>
      <div
        id="properties"
        class="tabcontent"
        bind:this={properties}
        role="tabpanel"
        aria-labelledby="property-tab"
      >
        <PropertySection />
      </div>
      <div
        id="attachments"
        class="tabcontent"
        role="tabpanel"
        bind:this={attachments}
        aria-labelledby="attachments-tab"
      >
        <div class="flex flex-row items-center justify-between pb-4 pr-2">
          <span class="title">Evidences</span>
          <CreateResource />
        </div>
        <Resources />
      </div>
      {#if $_selectedNode.type === Type.COMPONENT}
        <div
          id="parts"
          class="tabcontent"
          role="tabpanel"
          aria-labelledby="parts-tab"
        >
          <div class="flex flex-row justify-between pb-4 pr-2">
            <span class="title">Parts</span>
            <CreatePart />
          </div>
          <PartsSection />
        </div>
      {/if}
    </div>
  </div>
{/if}

<style>
  .active-button {
    border-bottom: 2px solid var(--text-base-100);
  }

  .active-button span {
    @apply text-base-content;
  }

  /* Style the tab content */
  .tabcontent {
    display: none;
    overflow: auto;
  }

  .title {
    @apply text-xl font-bold;
  }
</style>
