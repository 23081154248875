import { get, writable, derived } from "svelte/store";
import type { Writable } from "svelte/store";
import { GraphService, NodesService, StoriesService } from "@client";
import type { NodesWithStories, Stories, Edges } from "@client";
import { formatData } from "@utils/formatData";
import {
  isNodeInStoryGraph,
  isNodeOfParentOrChildStory,
  isNodeofStory,
  storyFormatData,
  type StoriesHierarchy,
} from "@utils/story.js";
import type { Edge, NodesHierarchy, NodeHierarchy } from "@utils/formatData";
import { displayStore } from "@stores/displaystore";
import { getTypeCategory } from "@utils/categories";

export type SelectedItemType = "node" | "story" | "edge" | undefined;

class DataStore {
  _nodes: Writable<NodesWithStories[] | []>;
  //todo contextualiser
  _nodesHierarchy: Writable<NodesHierarchy | null>;
  _alternativeNodesHierarchy: Writable<NodesHierarchy | null>;
  _graphData: Writable<{
    nodes: NodesWithStories[];
    links: Edge[];
  } | null>;
  _stories: Writable<Stories[]>;
  _updateSelection: Writable<number>;
  _selectedStory: Writable<StoriesHierarchy | null>;
  _selectedNode: Writable<NodesWithStories | null>;
  _selectedEdge: Writable<Edge | null>;
  _storyHierarchy: Writable<StoriesHierarchy[] | []>;
  _getSelectedItem: any;

  constructor() {
    this._nodes = writable([]);
    this._nodesHierarchy = writable(null);
    this._alternativeNodesHierarchy = writable(null);
    this._graphData = writable(null);
    this._stories = writable([]);
    this._updateSelection = writable(0);
    this._selectedStory = writable(null);
    this._selectedNode = writable(null);
    this._selectedEdge = writable(null);
    this._storyHierarchy = writable([]);
  }

  get nodes() : NodesWithStories[] {
    return get(this._nodes);
  }

  get nodesHierarchy() {
    return get(this._nodesHierarchy);
  }

  get alternativeNodesHierarchy() {
    return get(this._alternativeNodesHierarchy);
  }

  get graphData() {
    return get(this._graphData);
  }

  get stories() {
    return get(this._stories);
  }

  get selectedStory() {
    return get(this._selectedStory);
  }

  get selectedNode() {
    return get(this._selectedNode);
  }

  get selectedEdge() {
    return get(this._selectedEdge);
  }

  get storyHierarchy() {
    return get(this._storyHierarchy);
  }

  recupChildren = (nodes, id: String) => {
    const n = nodes.find((n) => n.id === Number(id));
    n.display = true;
    return n.children;
  };

  removeNodeRecurs = (nodes, id: Number) => {
    const n = nodes.find((n) => n.id === id);
    if (n) return [...nodes.filter((n) => n.id !== id), ...n.children];
    else {
      for (let child of nodes) {
        child.children = this.removeNodeRecurs(child.children, id);
      }
    }
    return nodes;
  };

  openHierarchy = (nodes, id: Number) => {
    const n = nodes.find((n) => n.id === id);
    if (n) return true;
    for (let child of nodes) {
      if (this.openHierarchy(child.children, id)) {
        child.display = true;
        return true;
      }
    }
    return false;
  };

  displayChildren = (nodes, open = true) => {
    for (let child of nodes) {
      child.display = open;
      child.children = this.displayChildren(child.children, open);
    }
    return nodes;
  };

  displayHierarchyForReferential = (open:boolean, nodesHierarchy:Writable<NodesHierarchy>) => {
    nodesHierarchy.update((nodes) => {
      let newHierarchy = { ...nodes };

      for (const type of Object.keys(newHierarchy)) {
        newHierarchy[type].display = open;
        newHierarchy[type].children = this.displayChildren(
          newHierarchy[type].children,
          open
        );
      }
      return Object.assign(nodes, newHierarchy);
    });
  }

  displayHierarchy = (open = true) => {
    this.displayHierarchyForReferential(open, this._nodesHierarchy);
    this.displayHierarchyForReferential(open, this._alternativeNodesHierarchy);
  };

  recursiveOpen = (nodes: NodeHierarchy[], node: NodesWithStories) => {
    const nFind = nodes.find((n) => n.id === node.id);
    if (nFind) {
      nFind.display = true;
      return true;
    } else {
      for (const n of nodes) {
        const res = this.recursiveOpen(n.children, node);
        if (res) {
          n.display = true;
          return true;
        }
      }
      return false;
    }
  };

  unSelect() {
    this._selectedStory.set(null);
    this._selectedEdge.set(null);
    this._selectedNode.set(null);
    this._updateSelection.update((val) => val + 1);
  }

  setSelectedStory(story: StoriesHierarchy) {
    story && this.setSelectedNode(null);
    story && this.setSelectedEdge(null);
    this._selectedStory.set(story);
    this._updateSelection.update((val) => val + 1);
  }

  setSelectedEdge(e) {
    e && this.setSelectedNode(null);
    this._selectedEdge.set(e);
    this._updateSelection.update((val) => val + 1);
  }

  displayNodeInTreeForReferential = (node: NodesWithStories, nodesHierarchy:Writable<NodesHierarchy>) => {
    node &&
    nodesHierarchy.update((nodes) => {
      let newHierarchy = { ...nodes };
      newHierarchy[node.type].display = this.openHierarchy(
        newHierarchy[node.type].children,
        node.id
      );
      return Object.assign(nodes, newHierarchy);
    });
  }

  displayNodeInTree(node: NodesWithStories) {
    this.displayNodeInTreeForReferential(node, this._nodesHierarchy);
    this.displayNodeInTreeForReferential(node, this._alternativeNodesHierarchy);

    const nodeCategory = getTypeCategory(node?.type);
    if (nodeCategory) {
      displayStore.setCategoryDisplay(nodeCategory, true);
    }
  }

  async setSelectedNode(node: NodesWithStories) {
    if (node) {
      try {
        const response = await NodesService.getNodeApiV1NodesIdGet(node.id);
        node = {...response}
      } catch (e) {
        console.log(e)
      }
      this.setSelectedEdge(null);
      if (node && (this.selectedNode === null || !this.isSelectedNode(node))) {
        this._selectedNode.set(node);
      }
      if (!isNodeofStory(node)) {
        this.setSelectedStory(null);
      }
      this.displayNodeInTree(node);
    } else {
      this._selectedNode.set(null);
    }
    this._updateSelection.update((val) => val + 1);
  }

  updateSelectedNode(node: NodesWithStories) {
    // try {
    //   const response = await NodesService.getNodeApiV1NodesIdGet(node.id);
    //   node = {...response}
    // } catch (e) {
    //   console.log(e)
    // }
    this.isSelectedNode(node) && this._selectedNode.set(node);
  }

  addNodeToAllNodes = (n) => {
    this._nodes.update((nodes) => [...nodes, n]);
  };

  updateNodeInAllNodes = (n: NodesWithStories) => {
    this._nodes.update((nodes) => [
      ...nodes.map((node: NodesWithStories) => {
        return node.id === n.id ? n : node;
      }),
    ]);
  };

  removeNodeToAllNodes = (id) => {
    this._nodes.update((n) => [
      ...n.filter((n: NodesWithStories) => n.id !== id),
    ]);
  };

  /** TODO: find a pattern to merge API response data with Graph Link Object structure
   *  without breaking types
   */
  updateSelectedEdge(edge: Edges) {
    this._selectedEdge.update((value) => {
      return { ...value, ...edge };
    });
  }

  addNodeToNodesHierarchyForReferential = (n: NodesWithStories, nodesHierarchy:Writable<NodesHierarchy>) => {
    nodesHierarchy.update((nodes) => {
      let newHierarchy = { ...nodes };
      let node = { ...n, display: false, children: [] };
      let pathSplit = node.path.split(".");
      if (pathSplit.length === 1) {
        newHierarchy[node.type].children.push(node);
      } else {
        let nodes = newHierarchy[node.type].children;
        for (let [index, pathV] of pathSplit.entries()) {
          if (index !== pathSplit.length - 1)
            nodes = this.recupChildren(nodes, pathV);
          else {
            nodes.push(node);
          }
        }
      }
      return Object.assign(nodes, newHierarchy);
    });
  }

  addNodeToNodesHierarchy = (n: NodesWithStories) => {
    this.addNodeToNodesHierarchyForReferential(n, this._nodesHierarchy);
    this.addNodeToNodesHierarchyForReferential(n, this._alternativeNodesHierarchy);
  };

  updateNodeInNodesHierarchyForReferential = (n: NodesWithStories, nodesHierarchy:Writable<NodesHierarchy>) => {
    nodesHierarchy.update((nodes) => {
      let newHierarchy = { ...nodes };
      let node = { ...n, display: false, children: [] };
      let pathSplit = node.path.split(".");
      if (pathSplit.length === 1) {
        newHierarchy[node.type].children = newHierarchy[node.type].children.map(
          (child) => (child.id === n.id ? { ...child, ...n } : child)
        );
      } else {
        let currentpathSplit = pathSplit[0]
        const children = newHierarchy[node.type].children.find(child => child.id.toString() === currentpathSplit).children
        pathSplit.shift()
        newHierarchy[node.type].children.find(child => child.id.toString() === currentpathSplit).children = this.updateChildrenNodeInNodesHierarchyForReferential(n, children, pathSplit)
      }
      return Object.assign(nodes, newHierarchy);
    });
  }

  updateChildrenNodeInNodesHierarchyForReferential = (n: NodesWithStories, newHierarchy:NodeHierarchy[], pathSplit:string[]) => {
    if (pathSplit.length === 1) {
    newHierarchy = newHierarchy.map(
      (child:NodeHierarchy) => (child.id === n.id ? { ...child, ...n } : child)
    );
    } else {
      let currentpathSplit = pathSplit[0]
      const children = newHierarchy.find(child => child.id.toString() === currentpathSplit).children
      pathSplit.shift()
      newHierarchy.find(child => child.id.toString() === currentpathSplit).children = this.updateChildrenNodeInNodesHierarchyForReferential(n, children, pathSplit)
    }
    return newHierarchy
  }

  updateNodeInNodesHierarchy = (n: NodesWithStories) => {
    this.updateNodeInNodesHierarchyForReferential(n, this._nodesHierarchy);
    this.updateNodeInNodesHierarchyForReferential(n, this._alternativeNodesHierarchy);
  };

  removeNodeToNodesHierarchyForReferential = (id:number, nodesHierarchy:Writable<NodesHierarchy> ) => {
    nodesHierarchy.update((nodes) => {
      let newHierarchy = { ...nodes };
      for (const type of Object.keys(newHierarchy)) {
        newHierarchy[type].children = this.removeNodeRecurs(
          newHierarchy[type].children,
          id
        );
      }
      return Object.assign(nodes, newHierarchy);
    });
  }

  removeNodeToNodesHierarchy = (id) => {
    this.removeNodeToNodesHierarchyForReferential(id, this._nodesHierarchy);
    this.removeNodeToNodesHierarchyForReferential(id, this._alternativeNodesHierarchy);
  };

  firstLoad = () => {
    GraphService.getGraphApiV1GraphGet().then((g) => {
      let data = formatData(g);
      this._nodes.set(data.allNodes);
      this._nodesHierarchy.set(formatData(g).nodesHierarchy);
      this._alternativeNodesHierarchy.set(formatData(g).nodesHierarchy);
      this._graphData.set({ nodes: data.nodes, links: data.links });
    });

    StoriesService.getAllApiV1StoriesGet().then((s) => {
      let data = storyFormatData(s as StoriesHierarchy[]);
      this._storyHierarchy.set(data);
      this._stories.set(s);
    });
  };

  addNodeAndLinkInGraph = (
    from: NodesWithStories,
    to: NodesWithStories,
    e: Edges
  ) => {
    this._graphData.update((graph) => {
      let newGraph = { ...graph };
      if (!newGraph.nodes.find((n) => n.id == to.id)) {
        newGraph.nodes.push(to);
      }
      if (!newGraph.nodes.find((n) => n.id == from.id)) {
        newGraph.nodes.push(from);
      }
      if (
        !newGraph.links.some(
          (l) => l.source.id == from.id && l.target.id == to.id
        )
      ) {
        newGraph.links.push({
          id: e.id,
          source: from,
          target: to,
          linkedId: e.linkedId,
          inserted_by: e.inserted_by,
          validated: e.validated,
          fake: false,
        });
      }

      return newGraph;
    });
  };

  addFakeLinkInGraph = (from: NodesWithStories, to: NodesWithStories) => {
    this._graphData.update((graph) => {
      let newGraph = { ...graph };
      newGraph.links.push({
        id: Math.floor(Math.random() * 1000000),
        source: from,
        target: to,
        inserted_by: "fake",
        validated: false,
        fake: true,
      });
      return newGraph;
    });
  };

  addFakeLinksInGraph = (
    links: { from: NodesWithStories; to: NodesWithStories }[]
  ) => {
    const linksUpdated = links.map((link) => {
      return {
        id: Math.floor(Math.random() * 1000000),
        source: link.from,
        target: link.to,
        inserted_by: "fake",
        validated: false,
        fake: true,
      };
    });
    this.graphData &&
      this._graphData.update((graph) => {
        let newGraph = { ...graph };
        newGraph.links = newGraph.links.concat(linksUpdated);
        return newGraph;
      });
  };

  removeNodeInGraph = (ids: Set<number>) => {
    this._graphData.update((graph) => {
      let newGraph = { ...graph };
      newGraph.nodes = newGraph.nodes.filter((node) => !ids.has(node.id));
      return newGraph;
    });
  };

  removeLinkInGraph = (id: number) => {
    this._graphData.update((graph) => {
      let newGraph = { ...graph };
      newGraph.links = newGraph.links.filter((edge) => edge.id !== id);
      // Remove orphan nodes
      newGraph.nodes = newGraph.nodes.filter((node) => {
        return (
          newGraph.links.filter(
            (e) => e.target.id == node.id || e.source.id == node.id
          ).length != 0
        );
      });
      return newGraph;
    });
  };

  removeFakeLinkInGraph = () => {
    this._graphData.update((graph) => {
      let newGraph = { ...graph };
      newGraph.links = newGraph.links.filter((edge) => !edge.fake);
      return newGraph;
    });
  };

  updateNodeInGraph = (n: NodesWithStories) => {
    this._graphData.update((graph) => {
      let newGraph = { ...graph };
      newGraph.nodes = graph.nodes.map((node: NodesWithStories) => {
        return node.id === n.id ? n : node;
      });
      newGraph.links = graph.links.map((link: Edge) => {
        return link.source.id === n.id ? { ...link, source: n } : link;
      });
      newGraph.links = newGraph.links.map((link: Edge) => {
        return link.target.id === n.id ? { ...link, target: n } : link;
      });
      return newGraph;
    });
  };

  updateLinkInGraph = (updatedLink: Edges) => {
    this._graphData.update((graph) => {
      let newGraph = { ...graph };
      newGraph.links = graph.links.map((link: Edge) => {
        return link.id === updatedLink.id ? { ...link, ...updatedLink } : link;
      });
      return newGraph;
    });
  };

  /**
   * Returns true if the node is the selected node.
   * Else returns false.
   *
   * @param {NodesWithStories} node the node to check
   * @returns wether given node is the selected node.
   */
  isSelectedNode = (node: NodesWithStories): boolean => {
    return node.id == this.selectedNode?.id;
  };

  /**
   * Returns true if the node is in the selected story.
   * Else returns false.
   *
   * @param {NodesWithStories} node the node to check
   * @returns wether given node is the selected story.
   */
  isInSelectedStory = (node: NodesWithStories): boolean => {
    return this.selectedStory && node !== null && isNodeInStoryGraph(node);
  };

  isNotInSelectedStory = (n: NodesWithStories) => {
    return this.selectedStory && n !== null && !isNodeInStoryGraph(n);
  };

  removeNodesFromStoriesHierarchy = (node_ids: number[]) => {
    this._storyHierarchy.update((stories) => {
      let newStories = [...stories];
      newStories.map((s) => {
        if (s.nodes.length != 0) {
          s.nodes = s.nodes.filter((n) => !node_ids.includes(n.id));
        }
        if (s.children.length != 0) {
          s.children.map((c) => {
            c.nodes = c.nodes.filter((n) => !node_ids.includes(n.id));
          });
        }
      });
      return newStories;
    });
  };

  insertNodesFromCurrentStory = (node) => {
    if (this.selectedStory) {
      this._selectedStory.update((story) => {
        let updatedStory = { ...story };
        updatedStory.nodes.push(node);
        this.updateStoryInAllStory(updatedStory);
        return updatedStory;
      });
    }
  };

  removeNodesFromCurrentStory = (node_ids: number[]) => {
    if (this.selectedStory) {
      this._selectedStory.update((story) => {
        let updatedStory = { ...story };
        updatedStory.nodes = updatedStory.nodes.filter(
          (node) => !node_ids.includes(node.id)
        );
        return updatedStory;
      });
    }
  };

  updateNodeFromStoriesHierarchy = (n: NodesWithStories) => {
    this._storyHierarchy.update((stories) => {
      stories.map((s) => {
        if (isNodeOfParentOrChildStory(n, s)) {
          if (isNodeofStory(n, s)) {
            s.nodes = s.nodes.filter((node) => node.id != n.id);
            s.nodes.push(n);
          } else {
            s.children.map((c) => {
              c.nodes = s.nodes.filter((node) => node.id != n.id);
              c.nodes.push(n);
              return c;
            });
          }
        }
        return s;
      });
      return stories;
    });
  };

  updateStoryInAllStory = (story: StoriesHierarchy) => {
    dataStore._stories.update((stories) => [
      ...stories.filter((s) => s.id != story.id),
      story,
    ]);
  };

  findNodeChildrenInChildren = (children, id: number) => {
    for(let child of children) {
      if(child.id == id)
        return child.children
      else {
        let retour = this.findNodeChildrenInChildren(child.children, id)
        if(retour)
          return retour
      }
    }
    return null
  }

  findNodeChildrenInHierarchy = (type: string, id: number | null) => {
    let children = this.nodesHierarchy[type].children
    if(id == null) return children
    for(let child of children) {
      if(child.id == id)
        return child.children
      else {
        let retour = this.findNodeChildrenInChildren(child.children, id)
        if(retour)
          return retour
      }
    }
    return null
  }
}

export const dataStore = new DataStore();
