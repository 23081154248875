<script lang="ts">
  import { displayStore } from "@stores/displaystore";
  
  import { nodeTypes } from "@utils/nodeTypes";

  let myInput = {value:"", alt:""};
  let currentFocus;
  export let list;
  export let nodeType;
  export let getInput;
  export let value = "";
  export let alt = "";
  export let name : string = "Node";
  export let placeholder: string = "";
  export let displaySearchIcon: boolean = false;
  export let compact: boolean = false;
  export let displayType: string = "";

  export const cleanInput = () => {
    myInput.value = "";
    myInput.alt = "";
  };

  export const getValue = () => {
    return myInput.alt;
  };


  function input(e) {
    var a,
      b,
      i,
      val = this.value;
    closeAllLists();
    if (!val) {
      return false;
    }
    currentFocus = -1;
    a = document.createElement("DIV");
    a.setAttribute("id", this.id + "autocomplete-list");
    a.setAttribute("class", "autocomplete-items");
    a.setAttribute("style", "cursor:pointer; font-size:12px; max-height:250px; overflow-y:auto; " + (compact ? "max-height:150px; overflow:auto;" : ""));
    this.parentNode.appendChild(a);

    if (placeholder == "" && nodeType){
      placeholder = `a ${nodeType}`
    }

    let loopOver = [];
    switch (displayType){
      case "concept":
        loopOver = list.filter(e => e.name.toUpperCase().includes(val.toUpperCase())).map(item => ({ text: item.name, nodeType: item.type, id: item.id }));
        break;
      default:
        loopOver = list.filter(e => e.toUpperCase().includes(val.toUpperCase())).map(item => ({ text: item, id: item.id }));
        break;
    }

    loopOver.forEach(e => {
      b = document.createElement("DIV");
      b.innerHTML = "";
      if (e.nodeType){
        b.innerHTML += `<span class="h-3 w-3 mr-1 ${(e.nodeType != "ecosystem_service" ? "rounded-2xl" : "")} inline-block" style="background-color:${nodeTypes[e.nodeType].color()};"></span>`
      }
      b.innerHTML += e.text.replace(val,`<strong>${val}</strong>`)
      b.innerHTML += "<input type='hidden' value='" + e.text + "' alt='" + e.id + "'>";
      b.addEventListener("click", function (e) {
        myInput.value = this.getElementsByTagName("input")[0].value;
        myInput.alt = this.getElementsByTagName("input")[0].alt;
        closeAllLists();
        myInput && getInput(myInput.alt);
      });
      b.addEventListener("mouseover", function (e) {
        this.style.textDecoration = "underline";
      });
      b.addEventListener("mouseout", function (e) {
        this.style.textDecoration = "initial";
      });
      a.appendChild(b);
    });
  }

  function keydown(e) {
    var x = document.getElementById(this.id + "autocomplete-list");
    if (x) x = x.getElementsByTagName("div");
    if (e.keyCode == 40) {
      currentFocus++;
      addActive(x);
    } else if (e.keyCode == 38) {
      currentFocus--;
      addActive(x);
    } else if (e.keyCode == 13) {
      e.preventDefault();
      if (currentFocus > -1) {
        if (x) x[currentFocus].click();
      }
    }
  }

  function keyup(e) {
    myInput.alt= myInput.value
    getInput(myInput.alt);
  }

  function addActive(x) {
    if (!x) return false;
    removeActive(x);
    if (currentFocus >= x.length) currentFocus = 0;
    if (currentFocus < 0) currentFocus = x.length - 1;
    x[currentFocus].classList.add("autocomplete-active");
  }

  function removeActive(x) {
    for (var i = 0; i < x.length; i++) {
      x[i].classList.remove("autocomplete-active");
    }
  }

  function closeAllLists(elmnt) {
    var x = document.getElementsByClassName("autocomplete-items");
    for (var i = 0; i < x.length; i++) {
      if (elmnt != x[i] && elmnt != myInput) {
        x[i].parentNode.removeChild(x[i]);
      }
    }
  }

  document.addEventListener("click", function (e) {
    closeAllLists(e.target);
  });
</script>

<div {...$$props}>
  <input
    id="myInput"
    type="text"
    {name}
    value={value}
    alt={alt}
    placeholder={`${placeholder}`}
    autocomplete="off"
    class={displayStore.form.base + displayStore.textSizes.md + " w-full rounded-lg " + (displaySearchIcon ? "myInputWithIcon" : "")}
    bind:this={myInput}
    on:input={input}
    on:keydown={keydown}
    on:keyup={keyup}
  />
  
  {#if displaySearchIcon}
    <svg class="searchIcon" xmlns="http://www.w3.org/2000/svg" viewBox="-5.0 -10.0 110.0 135.0">
      <path d="m65.289 69.711c-6.25 5.25-14.309 8.4141-23.102 8.4141-19.836 0-35.938-16.102-35.938-35.938s16.102-35.938 35.938-35.938 35.938 16.102 35.938 35.938c0 8.793-3.1641 16.852-8.4141 23.102l23.125 23.125c1.2188 1.2188 1.2188 3.2031 0 4.4219s-3.2031 1.2188-4.4219 0zm-0.019531-8.8594c4.1289-5.1016 6.6055-11.594 6.6055-18.664 0-16.383-13.305-29.688-29.688-29.688s-29.688 13.305-29.688 29.688 13.305 29.688 29.688 29.688c7.0703 0 13.562-2.4766 18.664-6.6055 1.6211-1.3125 3.1055-2.7969 4.418-4.418z"/>
    </svg>
  {/if}
</div>

<style>

  #myInput{
    margin-left:1px;
    margin-top:1px;
  }
  
  .myInputWithIcon {
    padding-left:30px;
  }

  .searchIcon {
    position: relative;
    top: -30px;
    left: 7px;
    width: 20px;
    height: 22px;
    fill: #999;
    margin-bottom: -22px;
  }

</style>