/** @module nodeTypes */
import { get } from "svelte/store";
import type { Type } from "../client/models/Type";
import { displayStore } from "@stores/displaystore";
import {
  EcosystemType,
  GoodsBenefitsType,
  ImpactType,
  Realm,
  ResponseType,
} from "@client";
const { _theme } = displayStore;

export type ValueOfType = `${Type}`;

export type typeConnection = {
  connection: "simple" | "multiple" | "linked";
  concept: ValueOfType;
  link?: ValueOfType;
}; // simple: concept in relation with an other concept
// multiple: concept in relation with multiple other concept (other concept are by default linked)
// link: concept in relation with an other concept that require an other link
export type NodeType = {
  color: Function;
  description: string;
  bannerImgSrc: string;
  label: string;
  form?: string;
  createto: typeConnection[]; //possibility to create a link to
  createfrom: typeConnection[]; // possibility to create a link from
  from: ValueOfType[]; // from concept used in the connection panel
  to: ValueOfType[]; // to concept used in the connection panel
  sectionto: string[];
  properties?;
};

export type NodeTypes = {
  [key in ValueOfType]: NodeType;
};

export type LabelTypes = {
  [key in ValueOfType]: string;
};

export const labelTypes: LabelTypes = {
  driver: "Driver",
  activity: "Activity",
  pressure: "Pressure",
  good_and_benefit: "Good and Benefit",
  complimentary_capital: "Complimentary capital",
  component: "Component",
  ecosystem_service: "Ecosystem service",
  response: "Response",
  impact_on_welfare: "Impact on welfare",
};

const getRadiogroupValue = (values: string[] | boolean[]) => {
  return values.map(v => {return {label: v, value : v}})
}

export const linkLabel = `${labelTypes["ecosystem_service"]} / ${labelTypes["activity"]}`;

export const nodeTypes: NodeTypes = {
  driver: {
    label: "Drivers",
    bannerImgSrc: "assets/conceptTypeBanners/driver.jpg",
    color: () => {
      return get(_theme) === "light" ? "#14B8A6" : "#5EEAD4";
    },
    createto: [
      { connection: "simple", concept: "activity" },
      { connection: "simple", concept: "impact_on_welfare" },
    ],
    createfrom: [
      { connection: "simple", concept: "good_and_benefit" },
      { connection: "simple", concept: "response" },
      { connection: "simple", concept: "complimentary_capital" },
    ],
    description:
      "Basic human needs and wants - based on Maslow's hierarchy of needs.",
    from: ["good_and_benefit", "response", "complimentary_capital"],
    to: ["activity", "impact_on_welfare"],
    sectionto: ["Motivate", "Demand"],
    properties: [
      // {
      //   name: "maslow_level",
      //   label: "Maslow Level",
      //   mandatory: true,
      //   field: {
      //     type: "select",
      //     values: Object.values(MaslowLevel),
      //   },
      //   emptyText: "No data.",
      // },
    ],
  },
  activity: {
    color: () => {
      return get(_theme) === "light" ? "#9333EA" : "#C4B5FD";
    },
    label: "Activities",
    bannerImgSrc: "assets/conceptTypeBanners/activity.jpg",
    description:
      'The things Individuals or groups of people "do" to satisfy their needs and wants.',
    createto: [
      { connection: "simple", concept: "pressure" },
      { connection: "simple", concept: "complimentary_capital" },
      { connection: "simple", concept: "good_and_benefit" },
      {
        connection: "linked",
        concept: "good_and_benefit",
        link: "ecosystem_service",
      },
    ],
    createfrom: [
      { connection: "simple", concept: "driver" },
      { connection: "simple", concept: "response" },
      { connection: "simple", concept: "complimentary_capital" },
      { connection: "simple", concept: "good_and_benefit" },
    ],
    from: ["driver", "response", "complimentary_capital", "good_and_benefit"],
    to: ["pressure", "good_and_benefit", "complimentary_capital"],
    sectionto: ["Cause", "Enable", "Generate"],
    properties: [
      {
        name: "is_inside_system_boundaries",
        label: "Inside system boundaries",
        mandatory: false,
        field: {
          type: "radiogroup",
          values: getRadiogroupValue([true, false]),
        },
        emptyText: "No data.",
      },
      {
        name: "is_outside_system_boundaries",
        label: "Outside system boundaries",
        mandatory: false,
        field: {
          type: "radiogroup",
          values: getRadiogroupValue([true, false]),
        },
        emptyText: "No data.",
      },
    ],
  },
  complimentary_capital: {
    color: () => {
      return get(_theme) === "light" ? "#3B82F6" : "#93C5FD";
    },
    label: "Complementary Capital",
    bannerImgSrc: "assets/conceptTypeBanners/complementaryCapital.jpg",
    description:
      "The social or economic capital inputs to (or outputs of) Activities.",
    from: ["activity"],
    to: ["driver", "activity"],
    sectionto: ["Trigger", "Used by"],
    createto: [
      { connection: "simple", concept: "driver" },
      { connection: "simple", concept: "activity" },
    ],
    createfrom: [{ connection: "simple", concept: "activity" }],
    properties: [
      // {
      //   name: "capital_type",
      //   label: "Complementary capital type",
      //   mandatory: true,
      //   field: {
      //     type: "select",
      //     values: Object.values(CapitalType),
      //   },
      //   emptyText: "No data.",
      // },
    ],
  },
  pressure: {
    color: () => {
      return get(_theme) === "light" ? "#F43F5E" : "#F9A8D4";
    },
    label: "Pressures",
    bannerImgSrc: "assets/conceptTypeBanners/pressure.jpg",
    description:
      "The chemical and/or biological and/or physical additions or subtractions to components of the environment resulting from activities. Pressure causes result in changes in state to environmental components.",
    createto: [{ connection: "simple", concept: "component" }],
    createfrom: [
      { connection: "simple", concept: "activity" },
      { connection: "simple", concept: "response" },
      { connection: "simple", concept: "component" },
    ],
    from: ["activity", "response", "component"],
    to: ["component"],
    sectionto: ["Changes state of"],
    properties: [
      {
        name: "is_additive",
        label: "Additive/substractive",
        mandatory: true,
        field: {
          type: "radiogroup",
          values: getRadiogroupValue(["additive","substractive"]),
          //values: Object.values(PressureAdditive),
        },
        emptyText: "No data.",
      },
    ],
  },
  component: {
    color: () => {
      return get(_theme) === "light" ? "#EAB308" : "#FDE047";
    },
    label: "State - Components",
    bannerImgSrc: "assets/conceptTypeBanners/component.jpg",
    description:
      'The concepts that constitute the Environment, including biotic, chemical or physical (energy) concepts. Some concepts are aggregations of "lower level" concepts.',
    createto: [
      { connection: "simple", concept: "ecosystem_service" },
      { connection: "simple", concept: "pressure" },
    ],
    createfrom: [
      { connection: "simple", concept: "pressure" },
      { connection: "simple", concept: "response" },
    ],
    from: ["pressure", "response"],
    to: ["ecosystem_service", "pressure"],
    sectionto: ["Can provide or can be", "Cause"],
    properties: [
      {
        name: "realm",
        label: "REALM",
        mandatory: true,
        field: {
          type: "select",
          values: Object.values(Realm),
        },
        emptyText: "No data.",
      },
      {
        name: "chemical_definition",
        label: "Chemical definition",
        mandatory: false,
        field: {
          type: "input",
        },
        emptyText: "No data.",
      },
      {
        name: "biological_definition",
        label: "Biological definition",
        mandatory: false,
        field: {
          type: "input",
        },
        emptyText: "No data.",
      },
      {
        name: "physical_definition",
        label: "Physical definition",
        mandatory: false,
        field: {
          type: "input",
        },
        emptyText: "No data.",
      },
      {
        name: "spatial_temporal_definition",
        label: "Spatio-temporal definition",
        mandatory: false,
        field: {
          type: "input",
        },
        emptyText: "No data.",
      },
    ],
  },
  ecosystem_service: {
    color: () => {
      return get(_theme) === "light" ? "#EAB308" : "#FDE047";
    },
    form: "rectangle",
    label: "State - Ecosystem Services",
    bannerImgSrc: "assets/conceptTypeBanners/es.jpg",
    description:
      "Environmental components and processes that have the potential to be converted (by an Activity) into human-usable goods and benefits.",
    createto: [
      {
        connection: "linked",
        concept: "good_and_benefit",
        link: "activity",
      },
    ],
    createfrom: [{ connection: "simple", concept: "component" }],
    from: ["component"],
    to: ["good_and_benefit"],
    sectionto: ["Are inputs to"],
    properties: [
      {
        name: "ecosystem_service_type",
        label: "Ecosystem service type",
        mandatory: true,
        field: {
          type: "select",
          values: Object.values(EcosystemType),
        },
        emptyText: "No data.",
      },
    ],
  },
  good_and_benefit: {
    color: () => {
      return get(_theme) === "light" ? "#10B981" : "#6EE7B7";
    },
    bannerImgSrc: "assets/conceptTypeBanners/gb.jpg",
    label: "Impacts - Goods and Benefits",
    description:
      "Are human-usable goods and benefits which result from a human Action and an Ecosystem Service.",
    createto: [
      { connection: "simple", concept: "driver" },
      { connection: "simple", concept: "impact_on_welfare" },
      { connection: "simple", concept: "activity" },
    ],
    createfrom: [
      {
        connection: "multiple",
        concept: "ecosystem_service",
        link: "activity",
      },
      { connection: "simple", concept: "activity" },
    ],
    from: ["ecosystem_service", "activity"],
    to: ["driver", "impact_on_welfare", "activity"],
    sectionto: ["Consume", "Supply", "Supply"],
    properties: [
      {
        name: "goods_benefits_type",
        label: "Goods and benefits type",
        mandatory: false,
        field: {
          type: "select",
          values: Object.values(GoodsBenefitsType),
        },
        emptyText: "No data.",
      },
    ],
  },
  impact_on_welfare: {
    color: () => {
      return get(_theme) === "light" ? "#F97316" : "#FDBA74";
    },
    label: "Impacts - Human Welfare",
    bannerImgSrc: "assets/conceptTypeBanners/impact.jpg",
    description:
      "Are the impacts on individuals and society of an over or undersupply of Goods and Benefits relative to their Needs/Wants (Drivers).",
    from: ["driver", "good_and_benefit"],
    to: ["response"],
    sectionto: ["Trigger"],
    createto: [{ connection: "simple", concept: "response" }],
    createfrom: [
      { connection: "simple", concept: "driver" },
      { connection: "simple", concept: "good_and_benefit" },
    ],
    properties: [
      {
        name: "impact_type",
        label: "Impact type",
        mandatory: true,
        field: {
          type: "select",
          values: Object.values(ImpactType),
        },
        emptyText: "No data.",
      },
    ],
  },
  response: {
    color: () => {
      return get(_theme) === "light" ? "#84CC16" : "#BEF264";
    },
    label: "Responses (as measures)",
    bannerImgSrc: "assets/conceptTypeBanners/response.jpg",
    description:
      "The various types of intervention that can be applied to adapt to or mitigate against Impacts on Welfare.",
    from: ["impact_on_welfare"],
    to: ["driver", "activity", "pressure", "component"],
    sectionto: ["Behavioural change", "Regulate", "Mitigate", "Intervene on"],
    createto: [
      { connection: "simple", concept: "driver" },
      { connection: "simple", concept: "activity" },
      { connection: "simple", concept: "pressure" },
      { connection: "simple", concept: "component" },
    ],
    createfrom: [{ connection: "simple", concept: "impact_on_welfare" }],
    properties: [
      {
        name: "response_type",
        label: "Response type",
        mandatory: true,
        field: {
          type: "select",
          values: Object.values(ResponseType),
        },
        emptyText: "No data.",
      },
      {
        name: "economically_viable",
        label: "Economically viable",
        mandatory: false,
        field: {
          type: "input",
        },
        emptyText: "No data.",
      },
      {
        name: "ecologically_sustainable",
        label: "Ecologically sustainable",
        mandatory: false,
        field: {
          type: "input",
        },
        emptyText: "No data.",
      },
      {
        name: "technologically_feasible",
        label: "Technologically feasible",
        mandatory: false,
        field: {
          type: "input",
        },
        emptyText: "No data.",
      },
      {
        name: "socially_tolerable",
        label: "Socially tolerable",
        mandatory: false,
        field: {
          type: "input",
        },
        emptyText: "No data.",
      },
      {
        name: "administratively_achievable",
        label: "Administratively achievable",
        mandatory: false,
        field: {
          type: "input",
        },
        emptyText: "No data.",
      },
      {
        name: "legally_permissible",
        label: "Legally permissible",
        mandatory: false,
        field: {
          type: "input",
        },
        emptyText: "No data.",
      },
      {
        name: "politically_expedient",
        label: "Politically expedient",
        mandatory: false,
        field: {
          type: "input",
        },
        emptyText: "No data.",
      },
      {
        name: "ethically_defensible",
        label: "Ethically defensible",
        mandatory: false,
        field: {
          type: "input",
        },
        emptyText: "No data.",
      },
      {
        name: "culturally_inclusive",
        label: "Culturally inclusive",
        mandatory: false,
        field: {
          type: "input",
        },
        emptyText: "No data.",
      },
      {
        name: "effectively_communicated",
        label: "Effectively communicated",
        mandatory: false,
        field: {
          type: "input",
        },
        emptyText: "No data.",
      },
    ],
  },
};
