<script lang="ts">
  import { ArrowDownIcon } from "@rgossiaux/svelte-heroicons/solid";
  import Pastille from "@components/icons/Pastille.svelte";
  import { nodeTypes } from "@utils/nodeTypes";
  import Carousel from "svelte-carousel";
  import DeleteButton from "../NodeEditor/edit/DeleteButton.svelte";
  import Can from "@components/AccessControl/Can.svelte";
  import { Roles } from "@utils/decorators";
  import DialogConfirmAction from "@components/dialog/DialogConfirmAction.svelte";
  import { PhotographIcon } from "@rgossiaux/svelte-heroicons/outline";
  import {
    XIcon,
    CheckIcon,
  } from "@rgossiaux/svelte-heroicons/solid";
  import { dataStore } from "@stores/datastore";
  import { ResourceType } from "../../../client/models/ResourceType";
  const { _selectedEdge } = dataStore;
  import { getCtx as getResourceCtx } from "@components/Actions/CreateResource/context";
  import { deleteEdge, updateEdge } from "@utils/edge";

  let { resources } = getResourceCtx();
  let dialogDeleteConnection;

  function onDelete() {
    deleteEdge($_selectedEdge.id);
  }
</script>

{#if $_selectedEdge}
  <div class="flex items-center justify-between my-2 mr-2">
    <div class="flex-none text-opacity-50 uppercase text-md text-base-content">
      Connection
    </div>
    <div class="flex-none">
      <Can roles={[Roles.STORY, Roles.DEV]}>
        <DeleteButton confirmDialog={dialogDeleteConnection}>
          <DialogConfirmAction
            bind:this={dialogDeleteConnection}
            type="deleteConnection"
            actionOnYes={onDelete}
          />
        </DeleteButton>
      </Can>
    </div>
  </div>
  <div class="flex flex-row">
    <div>
      <Pastille type={$_selectedEdge.source.type} />
      <span class="capitalize">{$_selectedEdge.source.type}</span>
      <div>
        <span
          class="navigate"
          on:click={() => dataStore.setSelectedNode($_selectedEdge.source)}
          >{$_selectedEdge.source.name}</span
        >
      </div>
    </div>
  </div>
  <div>
    <ArrowDownIcon class="w-4 h-4" />
  </div>
  <div class="text-xl font-bold">
    {nodeTypes[$_selectedEdge.source.type].sectionto[
      nodeTypes[$_selectedEdge.source.type].to.indexOf(
        $_selectedEdge.target.type
      )
    ]}
  </div>
  <div>
    <ArrowDownIcon class="w-4 h-4" />
  </div>
  <div>
    <Pastille type={$_selectedEdge.target.type} />
    <span class="capitalize">{$_selectedEdge.target.type}</span>
  </div>
  <div>
    <span
      class="navigate"
      on:click={() => dataStore.setSelectedNode($_selectedEdge.target)}
      >{$_selectedEdge.target.name}</span
    >
  </div>
  <div class="flex items-center justify-start mt-1 mb-1 text-xs">
    {#if $_selectedEdge.validated }
      <CheckIcon class="w-4 h-4 mr-2 rounded-full btn-success" />
    {:else}
      <XIcon class="w-4 h-4 mr-2 rounded-full btn-error" />
    {/if}
    <span>
      Created by: {$_selectedEdge.inserted_by}
    </span>
    <button
        type="button"
        class="ml-2 kg-btn-outline"
        style="padding: 6px; height: 1.6rem; min-height: 1.6rem;"
        disabled={!$_selectedEdge.source.validated || !$_selectedEdge.target.validated}
        on:click={() => updateEdge($_selectedEdge.id, { validated: !$_selectedEdge.validated })}
      >
        {$_selectedEdge.validated ? "invalidate" : "validate"}
      </button>
  </div>
  <div>
    {#await resources.init("edge", $_selectedEdge.id) then}
      {#if $resources && $resources.filter((r) => r.type === ResourceType.PHOTO && r.soft_deleted_by === null).length !== 0}
        <Carousel>
          {#each $resources.filter((r) => r.type === ResourceType.PHOTO && r.soft_deleted_by === null) as resource}
            <img
              src={resource.url}
              class="object-cover h-full col-span-2 rounded-r-lg"
              alt={resource.description}
            />
          {/each}
        </Carousel>
      {:else}
        <div
          class="flex flex-col items-center justify-center mr-4 rounded-lg bg-base-300 h-28"
        >
          <PhotographIcon class="opacity-50 w-9 h-9 text-base-content" />
          <span class="opacity-50 text-base-content"
            >There is no picture at the moment</span
          >
        </div>
      {/if}
    {/await}
  </div>
{/if}

<style>
  .navigate {
    @apply cursor-pointer;
  }
</style>
