<script lang="ts">
  import Autocomplete from "@components/AutocompleteAlt.svelte";
  import { dataStore } from "@stores/datastore";
  import { addNodeToCurrentStory } from "@utils/story";
  import CreateTriple from "@components/dialog/CreateTriple.svelte";

  const { _selectedStory, _nodes } = dataStore;

  let autoComplete;
  let listNodeForStory;
  let selectedType = "node";
  let createLink;
  let nodeId;
  let listExistantNode = [];
  let saveDisable = false;

  const getNodeFromId = (id: number) => {
    return $_nodes.find((n) => n.id == id);
  };

  function setNodeName(id) {
    nodeId = id;
    saveDisable = !listNodeForStory.find((n) => n.id == id);
  }

  function updateExistantPart() {
    listExistantNode = $_selectedStory.nodes.map((n) => n.id);
  }

  function updateListNodeForPart() {
    listNodeForStory = $_nodes
      .filter((n) => !listExistantNode.includes(n.id))
      .sort((a,b) => { return (
        a.type < b.type ? -1 : 
          (
            a.type > b.type ? 1 : 
            (
              a.name < b.name ? -1 :
              (
                a.name > b.name ? 1 : 0
              )
            )
          )
        );}
      );
  }

  function create_part() {
    let nodeToAdd = getNodeFromId(nodeId);
    addNodeToCurrentStory([nodeToAdd]);
    createLink.show(null, nodeToAdd);
    autoComplete.cleanInput();
  }

  $: $_selectedStory && updateExistantPart();
  $: $_selectedStory && updateListNodeForPart();
  $: $_nodes && updateListNodeForPart();
</script>

<!--
@component
Section property section. 
Displays concept custom properties alongside an edit button. 
-->
<CreateTriple bind:this={createLink} />
{#if $_selectedStory}
  <div class="top-container flex top-container flex gap-2">
    <Autocomplete
      bind:this={autoComplete}
      list={listNodeForStory}
      nodeType={selectedType}
      displayType={"concept"}
      getInput={setNodeName}
      placeholder="Search concept to include"
      displaySearchIcon={true}
    />
    <button
      class="gap-2 mt-1 normal-case rounded-xl btn-outline text-base-content btn btn-sm"
      disabled={saveDisable}
      on:click={() => create_part()}>Include in story
    </button>
  </div>
{/if}
