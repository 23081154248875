import { EdgesService, type Resource } from "@client";
import { dataStore } from "@stores/datastore";
import type { Edge } from "@utils/formatData";
import type { NodesWithStories } from "@client";
import type { UpdateEdge } from "@models/UpdateEdge";
import { addNodeToCurrentStory } from "@utils/story";

export const edgeEnable = (e: Edge) => {
  return !e.fake;
};

export const deleteEdge = (id: number) => {
  EdgesService.deleteEdgeApiV1EdgesIdDelete(id).then(() => {
    let linkedId = dataStore.graphData.links.find(
      (edge) => edge.id == id
    ).linkedId;
    dataStore.removeLinkInGraph(id);
    if (linkedId) {
      dataStore.removeLinkInGraph(linkedId);
    }
    dataStore.setSelectedEdge(null);
  });
};

export const createEdge = (from: NodesWithStories, to: NodesWithStories) => {
  EdgesService.insertEdgeApiV1EdgesPost({
    _from: `${from.id}`,
    _to: `${to.id}`,
  }).then((res) => {
    dataStore.addNodeAndLinkInGraph(from, to, res);
    dataStore.setSelectedNode(to);
  });
};

export const createEdgeAndBindNodeToCurrentStory = (from: NodesWithStories, to: NodesWithStories, node: NodesWithStories, goto: Boolean = true) => {
  EdgesService.insertEdgeApiV1EdgesPost({
    _from: `${from.id}`,
    _to: `${to.id}`,
  }).then((res) => {
    addNodeToCurrentStory([(node.id == from.id ? to : from)]);

    dataStore.addNodeAndLinkInGraph(from, to, res);
    goto && dataStore.setSelectedNode(to);
  }).catch((res) => {
    addNodeToCurrentStory([(node.id == from.id ? to : from)]);
  })
};

export const createEdgeLinked = (
  from_ecosystem: NodesWithStories,
  from_activity: NodesWithStories,
  _to: NodesWithStories,
) => {
  EdgesService.insertLinkedEdgeApiV1EdgesLinkedPost({
    _to: `${_to.id}`,
    from_ecosystem_id: `${from_ecosystem.id}`,
    from_activity_id: `${from_activity.id}`,
  }).then((res) => {
    const edgeOrganized =
      res[0].fromId === from_ecosystem.id ? res : [res[1], res[0]];
    dataStore.addNodeAndLinkInGraph(from_ecosystem, _to, edgeOrganized[0]);
    dataStore.addNodeAndLinkInGraph(from_activity, _to, edgeOrganized[1]);
    dataStore.setSelectedNode(_to);
  });
};

export const createEdgeLinkedAndBindNodeToCurrentStory = (
  from_ecosystem: NodesWithStories,
  from_activity: NodesWithStories,
  _to: NodesWithStories,
  node: NodesWithStories,
  goto: Boolean = true
) => {
  EdgesService.insertLinkedEdgeApiV1EdgesLinkedPost({
    _to: `${_to.id}`,
    from_ecosystem_id: `${from_ecosystem.id}`,
    from_activity_id: `${from_activity.id}`,
  }).then((res) => {
    const edgeOrganized =
      res[0].fromId === from_ecosystem.id ? res : [res[1], res[0]];
    dataStore.addNodeAndLinkInGraph(from_ecosystem, _to, edgeOrganized[0]);
    dataStore.addNodeAndLinkInGraph(from_activity, _to, edgeOrganized[1]);

    if(node.id != from_ecosystem.id)
      addNodeToCurrentStory([from_ecosystem]);
    if(node.id != from_activity.id)
      addNodeToCurrentStory([from_activity]);
    if(node.id != _to.id)
      addNodeToCurrentStory([_to]);

    goto && dataStore.setSelectedNode(_to);
  }).catch((res) => {
    if(node.id != from_ecosystem.id)
      addNodeToCurrentStory([from_ecosystem]);
    if(node.id != from_activity.id)
      addNodeToCurrentStory([from_activity]);
    if(node.id != _to.id)
      addNodeToCurrentStory([_to]);
  })
};

export const updateEdge = async (
  edgeId: number,
  data: UpdateEdge
): Promise<void> => {
  try {
    const response = await EdgesService.udpateEdgeApiV1EdgesIdPut(edgeId, data);
    dataStore.updateSelectedEdge(response);
    dataStore.updateLinkInGraph(response);
  } catch (e) {
    console.error(e)
  }
};

export const getEdgeResourcesAsync = (edgeId: number) => {
  return EdgesService.getEdgeResourcesApiV1EdgesIdResourcesGet(edgeId);
};

export const addEdgeResourceAsync = (edgeId: number, data: Resource) => {
  return EdgesService.addResourceToEdgeApiV1EdgesIdResourcesPost(edgeId, data);
};
